import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./style.scss";
import kammerzell from '../../videos/KAMM FINAL.mp4';

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  return (
    <Layout location={location} title={siteTitle} className={'layout layout-loaded'}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      {post.frontmatter.hero && post.frontmatter.hero.childImageSharp && (
        <Img fluid={post.frontmatter.hero.childImageSharp.fluid} alt="image" className="hero"/>
      )}
      <article>
        <header>
          <h1>
            {post.frontmatter.title}
          </h1>
          <h3>{post.frontmatter.archi}</h3>
          <p>{post.frontmatter.address}<br/>{post.frontmatter.size}</p>

          {(post.frontmatter.archi2) && (
            <>
              <h3>{post.frontmatter.archi2}</h3>
              <p>{post.frontmatter.address2}<br/>{post.frontmatter.size2}</p>
            </>
          )}

          {(post.frontmatter.archi3) && (
            <>
              <h3>{post.frontmatter.archi3}</h3>
              <p>{post.frontmatter.address3}<br/>{post.frontmatter.size3}</p>
            </>
          )}

        </header>
        <main>
          <section>
            <p
              data-sal="slide-up"
              data-sal-delay="250"
              data-sal-duration="1000"
              data-sal-easing="ease">{post.frontmatter.description}</p>
            {(post.frontmatter.description) && (
              <p
                style={{marginTop: "30px"}}
                data-sal="slide-up"
                data-sal-delay="250"
                data-sal-duration="1000"
                data-sal-easing="ease">{post.frontmatter.description2}</p>
            )}
            {(post.frontmatter.description3) && (
              <p
                style={{marginTop: "30px"}}
                data-sal="slide-up"
                data-sal-delay="250"
                data-sal-duration="1000"
                data-sal-easing="ease">{post.frontmatter.description3}</p>
            )}
            {(post.frontmatter.video) && (
                <video controls muted autoPlay style={{margin: "120px 0px -80px", width: "100%"}}>
                  <source src={kammerzell} type="video/mp4" />
                </video>
            )}
          </section>

          <section dangerouslySetInnerHTML={{ __html: post.html }} />
        </main>
      </article>
      <section style={{marginTop: "10px"}}>
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← PROJET PRECEDENT
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  PROJET SUIVANT →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        archi
        address
        size
        archi2
        address2
        size2
        archi3
        address3
        size3
        hero {
          childImageSharp {
            fluid(maxWidth: 2160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        description
        description2
        description3
        video
      }
    }
  }
`
